<template>
  <div
    id="contact-form-section"
    v-loading.fullscreen.lock="mainLoader"
    class="white-background-section pt-5"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="container">
      <validation-errors
        v-if="validationErrors.length"
        :errors="validationErrors"
        class="mt-5"
      ></validation-errors>
      <el-row>
        <el-col
          :xl="12"
          :lg="12"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <el-form
            id="contactForm"
            ref="contactForm"
            :rules="submitData.rules"
            :model="submitData"
            autocomplete="false"
            @submit.native.prevent
            @validate="handleContactUsFormFieldInput"
          >
            <el-row>
              <!-- <p class="bottom-text">Take a moment to read our <a target="_blank" rel="noopener noreferer" href="https://support.scotlandeuropa.com/portal/en-gb/kb/globalscot">FAQs</a><br/><br/></p> -->
              <!-- <el-row>
                <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                <label for="select">
                What is your enquiry about?
                  <span class="sr-only"
                        aria-live="polite"
                        :hidden="! this.hasInlineValidationErrorFor('select')"
                        v-text="this.getInlineValidationErrorFor('select')"></span>
                </label>
                 <span class="is-custom-asterisk">*</span>
                  <el-form-item prop="select">
                      <v-select
                        v-model="submitData.select"
                        :options="options"
                        placeholder="Please select"
                        :multiple="false"
                        :clearable="false"
                        label="name"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="true"
                        id="select"
                      />
                  </el-form-item>
                  </el-col>
                </el-row> -->
              <el-row>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <label for="firstName">
                    First Name
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('firstName')"
                      v-text="getInlineValidationErrorFor('firstName')"
                    ></span>
                  </label>
                  <span class="is-custom-asterisk">*</span>
                  <el-form-item prop="firstName">
                    <el-input
                      id="firstName"
                      v-model="submitData.firstName"
                      aria-required="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <label for="lastName">
                    Last Name
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('lastName')"
                      v-text="getInlineValidationErrorFor('lastName')"
                    ></span>
                  </label>
                  <span class="is-custom-asterisk">*</span>
                  <el-form-item
                    prop="lastName"
                    required
                  >
                    <el-input
                      id="lastName"
                      v-model="submitData.lastName"
                      aria-required="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <label for="emailAddress">
                    Email Address
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="
                        !hasInlineValidationErrorFor('emailAddress')
                      "
                      v-text="getInlineValidationErrorFor('emailAddress')"
                    ></span>
                  </label>
                  <span class="is-custom-asterisk">*</span>
                  <el-form-item
                    prop="emailAddress"
                  >
                    <el-input
                      id="emailAddress"
                      v-model="submitData.emailAddress"
                      aria-required="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <label for="number">
                    Contact Number
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('number')"
                      v-text="getInlineValidationErrorFor('number')"
                    ></span>
                  </label>
                  <span class="is-custom-asterisk">*</span>
                  <el-form-item prop="number">
                    <el-input
                      id="number"
                      v-model="submitData.number"
                      maxlength="20"
                      autocomplete="false"
                      aria-required="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- <el-row>
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <label for="company">
                       Company name and trading address
                       <span class="sr-only"
                             aria-live="polite"
                             :hidden="! this.hasInlineValidationErrorFor('company')"
                             v-text="this.getInlineValidationErrorFor('company')"></span>
                    </label>
                    <span class="is-custom-asterisk">*</span>
                  <el-form-item prop="company">
                      <el-input v-model="submitData.company" id="company"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->

              <el-row>
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <label for="message">
                    Your message for us
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('message')"
                      v-text="getInlineValidationErrorFor('message')"
                    ></span>
                  </label>
                  <span class="is-custom-asterisk">*</span>
                  <el-form-item
                    prop="message"
                  >
                    <el-input
                      id="message"
                      v-model="submitData.message"
                      placeholder="please leave your message"
                      type="textarea"
                      :rows="5"
                      maxlength="2500"
                      minlength="5"
                      aria-required="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- <el-row>
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <label for="attachments">
                    Attachments
                    <span class="sr-only"
                          aria-live="polite"
                          :hidden="! this.hasInlineValidationErrorFor('attachments')"
                          v-text="this.getInlineValidationErrorFor('attachments')"></span>
                  </label>
                  <el-form-item prop="attachments" id="attachments">
                      <el-upload
                        action=""
                        class="avatar-uploader"
                        v-bind:class="{ hidden: !canUploadFiles }"
                        :multiple="false"
                        :auto-upload="false"
                        :on-change="onFileChange"
                        :on-remove="onFileChange"
                        :limit="3"
                      >
                        <p>Click To Add Files <i class="el-icon-upload"></i></p>
                      </el-upload>
                  </el-form-item>
                </el-col>
              </el-row> -->

              <label for="agreeCheck">
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!hasInlineValidationErrorFor('agreeCheck')"
                  v-text="getInlineValidationErrorFor('agreeCheck')"
                ></span>
              </label>
              <el-form-item
                prop="agreeCheck"
              >
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                >
                  <el-checkbox
                    id="agreeCheck" 
                    v-model="submitData.agreeCheck"
                  >
                    <p>
                      I acknowledge that I have read and understand the
                      <a href="/privacy-policy">privacy policy</a>
                    </p>
                  </el-checkbox>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
          <div>
            <el-button
              class="submit-btn"
              type="primary"
              @click="submitForm()"
            >
              Submit
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <h2 class="involve-title mt-4">
            Or get in touch directly
          </h2>
          <p class="involve-sub-text font-weight-bold">
            EU Policy team
          </p>
          <ul class="involve-list">
            <li>
              Sarah English: Head of Brussels Office:
              <a href="mailto:Sarah.English@scotent.co.uk">Sarah.English@scotent.co.uk</a>, +32 2 282 83 14
            </li>
            <li>
              Lucie McAnea: Policy &amp; Communications Executive:
              <a href="mailto:Lucie.McAnea@scotent.co.uk">Lucie.McAnea@scotent.co.uk</a>, +32 2 282 83 10
            </li>
            <li>
              Marija Ivoninaite: Senior EU Policy &amp; Funding Executive:
              <a href="mailto:Marija.Ivoninaite@scotent.co.uk">Marija.Ivoninaite@scotent.co.uk</a>, +32 2 282 83 09
            </li>
            <li>
              Lorna Murphy: Policy and Communications Executive:
              <a href="mailto:Lorna.Murphy@scotent.co.uk">Lorna.Murphy@scotent.co.uk</a>, +32 2 282 83 13
            </li>
            <li>
              James Sharples: Senior EU Policy &amp; Funding Executive:
              <a href="mailto:James.Sharples@scotent.co.uk">James.Sharples@scotent.co.uk</a>, +32 2 282 83 12
            </li>
            <li>
              Valentine Willmann: Senior EU Policy &amp; Funding Executive:
              <a href="mailto:Valentine.Willmann@scotent.co.uk">Valentine.Willmann@scotent.co.uk</a>, +32 2 282 83 06
            </li>
            <li>
              Eleanor Kenny: Policy &amp; Communications Manager:
              <a href="mailto:Eleanor.Kenny@scotent.co.uk">Eleanor.Kenny@scotent.co.uk</a>, +32 2 282 83 04
            </li>
          </ul>
          <p class="involve-sub-text font-weight-bold mt-4">
            EU Funding team
          </p>
          <ul class="involve-list">
            <li>
              Caroline Coleman: Senior EU Funding Executive:
              <a href="mailto:Caroline.Coleman@scotent.co.uk">Caroline.Coleman@scotent.co.uk</a>, +44 141 468 51 49
            </li>
            <li>
              Ingrid Green: Senior EU Funding Executive:
              <a href="mailto:Ingrid.Green@scotent.co.uk">Ingrid.Green@scotent.co.uk</a>, +44 141 468 55 08
            </li>
          </ul>
          <p class="involve-sub-text font-weight-bold mt-4">
            EU Compliance team
          </p>
          <ul class="involve-list">
            <li>
              Mark Elliott: EU Compliance Manager:
              <a href="mailto:Mark.Elliott@scotent.co.uk">Mark.Elliott@scotent.co.uk</a>, +44 141 468 58 75
            </li>
            <li>
              Alexis Cahill: Senior EU Compliance Executive:
              <a href="mailto:Alexis.Cahill@scotent.co.uk">Alexis.Cahill@scotent.co.uk</a>, +44 141 343 79 36
            </li>
            <li>
              Andrew Callaghan: Senior EU Compliance Executive:
              <a href="mailto:Andrew.Callaghan@scotent.co.uk">Andrew.Callaghan@scotent.co.uk</a>, +44 141 468 50 48
            </li>
            <li>
              Alan Christie: Senior EU Compliance Executive:
              <a href="mailto:Alan.Christie@scotent.co.uk">Alan.Christie@scotent.co.uk</a>, +44 141 343 79 48
            </li>
            <li>
              Lara Fajeyisan: EU Claims Executive:
              <a href="mailto:Lara.Fajeyisan@scotent.co.uk">Lara.Fajeyisan@scotent.co.uk</a>, +44 141 468 57 75
            </li>
            <li>
              Elizabeth Fitzpatrick: Senior EU Compliance Executive:
              <a href="mailto:Elizabeth.Fitzpatrick@scotent.co.uk">Elizabeth.Fitzpatrick@scotent.co.uk</a>, +44 141 468 61 38
            </li>
            <li>
              Tracey McKelvie: Senior EU Compliance Executive:
              <a href="mailto:Tracey.McKelvie@scotent.co.uk">Tracey.McKelvie@scotent.co.uk</a>, +44 141 468 62 34
            </li>
            <li>
              Gail Oakley: EU Claims Executive:
              <a href="mailto:Gail.Oakley@scotent.co.uk">Gail.Oakley@scotent.co.uk</a>, +44 141 468 54 00
            </li>
            <li>
              Fiona Redpath: Senior EU Compliance Executive:
              <a href="mailto:Fiona.Redpath@scotent.co.uk">Fiona.Redpath@scotent.co.uk</a>, +44 141 468 53 78
            </li>
            <li>
              Moira Scoon: EU Claims Executive:
              <a href="mailto:Moira.Scoon@scotent.co.uk">Moira.Scoon@scotent.co.uk</a>, +44 141 468 50 48
            </li>
          </ul>
          <p class="involve-sub-text font-weight-bold mt-4">
            Facilities &amp; Admin team
          </p>
          <ul class="involve-list">
            <li>
              Helena De Raedemaeker: Scotland House Coordinator - Conference
              Centre &amp; Facilities:
              <a href="mailto:Helena.DeRaedemaeker@scotent.co.uk">Helena.DeRaedemaeker@scotent.co.uk</a>, +32 2 282 83 15
            </li>
            <li>
              Egbert Flipse: Financial Administrator:
              <a href="mailto:Egbert.Flipse@scotent.co.uk">Egbert.Flipse@scotent.co.uk</a>: +32 2 282 83 04
            </li>
            <li>
              Scott Govan: Senior Administrator:
              <a href="mailto:Scott.Govan@scotent.co.uk">Scott.Govan@scotent.co.uk</a>, +44 141 468 61 03
            </li>
            <li>
              Jessica Watts: Office Manager:
              <a href="mailto:Jessica.Watts@scotent.co.uk">Jessica.Watts@scotent.co.uk</a>, +32 2 282 83 01
            </li>
          </ul>
          <p class="involve-sub-text font-weight-bold mt-4">
            Stage/internship team
          </p>
          <ul class="involve-list">
            <li>
              Louis Leslie: EU Policy &amp; Events Stagiaire:
              <a href="mailto:Louis.Leslie@scotent.co.uk">Louis.Leslie@scotent.co.uk</a>, +32 2 896 54 61
            </li>
            <li>
              Emma Cosmao: EU Policy &amp; Events Stagiaire:
              <a href="mailto:Emma.Cosmao@scotent.co.uk">Emma.Cosmao@scotent.co.uk</a>, +32 2 896 54 70
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import { validate } from "email-validator";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
  "image/jpeg",
  "image/png",
  "video/mpeg",
  "video/webm",
  "video/mp4",
  "video/ogg",
];
const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200mb
const MAX_FILES = 3;

export default {
  components: {
    validationErrors,
  },
  mixins: [inlineValidationErrorsMixin],
  data() {
    let validateContactNumber = (rule, value, callback) => {
      let reg = /^\+?\d*$/;
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 10) {
        callback(
          new Error("Contact number may not be less than 10 characters")
        );
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Contact number field must be valid"));
      } else {
        callback();
      }
    };

    let validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else if (value.length > 255) {
        callback(new Error("Email address is too long"));
      } else {
        callback();
      }
    };

    let validateMediaUpload = (rule, fileList, callback) => {
      fileList.forEach((file) => {
        if (ALLOWED_FILE_TYPES.indexOf(file.type) == -1) {
          callback(
            new Error(
              "The files must be of type: jpg, png, jpeg, mp4, ogv, webm, pdf, ppt, pptx, doc, docx, xls, xlsx, odt, odp, ods."
            )
          );
        }
        if (MAX_FILE_SIZE < file.size) {
          callback(new Error("Each file's size must be less than 200MB."));
        }
      });
      callback();
    };

    let validateAgreeCheck = (rule, value, callback) => {
      if (value === false) {
        callback(
          new Error("Please confirm you have read the terms & conditions")
        );
      } else {
        callback();
      }
    };

    return {
      mainLoader: false,
      submitData: {
        select: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        company: "",
        number: "",
        message: "",
        agreeCheck: false,
        attachments: [],
        rules: {
          select: [
            {
              required: true,
              message: "Select what your enquiry is about",
              trigger: "blur",
            },
          ],
          firstName: [
            {
              required: true,
              message: "Enter your first name",
              trigger: "blur",
            },
            {
              max: 64,
              message: "First name may not be greater than 64 characters",
              trigger: "blur",
            },
          ],
          lastName: [
            {
              required: true,
              message: "Enter your last name",
              trigger: "blur",
            },
            {
              max: 64,
              message: "Last name may not be greater than 64 characters",
              trigger: "blur",
            },
          ],
          emailAddress: [{ validator: validateEmail, required: true }],
          company: [
            {
              required: true,
              message: "Enter your company name",
              trigger: "blur",
            },
          ],
          number: [{ validator: validateContactNumber, required: true }],
          message: [
            { required: true, message: "Enter your message", trigger: "blur" },
            {
              min: 5,
              max: 2500,
              message: "Message must be between 5 and 2500 characters",
              trigger: "change",
            },
          ],
          agreeCheck: [{ validator: validateAgreeCheck, required: true }],
          attachments: [{ validator: validateMediaUpload, required: false }],
        },
      },
      options: [
        { name: "None" },
        { name: "Share a good news story" },
        { name: "Help with registration" },
        { name: "Request help from Scotland Europa" },
        { name: "Account maintenance" },
        { name: "Using the Community Feed" },
        { name: "Events and Webinars" },
        { name: "Report a bug" },
        { name: "Closing or Suspending my account" },
        { name: "General feedback" },
      ],
      validationErrors: [],
    };
  },
  computed: {
    canUploadFiles() {
      return this.submitData.attachments.length < MAX_FILES;
    },
  },
  methods: {
    onFileChange(file, fileList) {
      this.validationErrors = [];
      this.submitData.attachments = fileList.map((f) => f.raw);
      //console.log(this.submitData.attachments.length)
    },
    submitForm(event) {
      this.$refs["contactForm"].validate((valid) => {
        if (valid) {
          this.mainLoader = true;
          let formData = new FormData();

          formData.append("about", this.submitData.select.name);
          formData.append("first_name", this.submitData.firstName);
          formData.append("last_name", this.submitData.lastName);
          formData.append("contact_number", this.submitData.number);
          formData.append("email", this.submitData.emailAddress);
          formData.append("company_name", this.submitData.company);
          formData.append("message", this.submitData.message);
          formData.append("privacy_policy", this.submitData.agreeCheck);
          this.submitData.attachments.forEach((attachment) => {
            formData.append("attachments[]", attachment);
          });

          gsApi.users
            .contactUs(formData)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.$alert("Thanks for your enquiry.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }).then(() => {
                this.$router.push("feed");
              });
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;

                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }

                document
                  .getElementById("contact-form-section")
                  .scrollIntoView({ behavior: "smooth", block: "start" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];

          this.$refs.contactForm.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });

          this.$nextTick(() => {
            document
              .getElementById("contact-form-section")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("contactForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    handleContactUsFormFieldInput() {
      this.handleSingleFormFieldInput("contactForm");
    },
  },
  // end of method
};
</script>
<style lang="scss">
.bottom-text {
  font-weight: 390;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
.number-select {
  width: 100px;
}
a {
  color: #a8308c;
  text-decoration: underline;
  font-weight: bold;
}
.submit-btn {
  width: 300px;
  margin-top: 20px;
}
.label-padding {
  padding-left: 0.5rem;
}
#attachments .el-upload {
  min-height: unset;
  box-shadow: none;
  height: 70px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.hidden .el-upload {
  display: none;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-add-new {
  font-size: $font-size-28;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: $line-height-178;
  text-align: center;
}
/* Not used in this page , and has impact on posts page
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}*/
</style>

