<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <div class="involve-heading-text mt-3">
          <div class="involve-data">
            <div>
              <el-col
                class
                :md="24"
                :lg="12"
              >
                <div class="left-text pr-2">
                  <h1 class="involve-title">
                    {{ text.heading }}
                  </h1>
                  <p class="involve-sub-text first-sub">
                    {{ text.sub_1 }}
                  </p>
                  <p class="involve-sub-text first-sub">
                    {{ text.sub_2 }}
                  </p>
                  <p class="involve-sub-text">
                    {{ text.listStart }}
                  </p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in bulletPoints"
                        :key="point.text"
                      >
                        {{ point.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </div>
            <div>
              <el-col
                :md="24"
                :lg="12"
              >
                <div class="invlove-img mt-5">
                  <img
                    alt="Conference Centre"
                    src="../../../../static/img/conference-centre.jpg"
                  />
                </div>
              </el-col>
            </div>
            <div>
              <el-col
                class
                :md="24"
              >
                <h1 class="involve-title mb-3">
                  {{ text.roomsAndRatesHeading }}
                </h1>
                <div class>
                  <ul class="involve-list">
                    <li
                      v-for="point in roomsAndRatesBulletPoints"
                      :key="point.text"
                    >
                      {{ point.text }}
                    </li>
                  </ul>

                  <p class="involve-sub-text first-sub">
                    {{ roomsAndRatesText.sub_1 }}
                  </p>
                  <p
                    class="involve-sub-text first-sub"
                    v-html="roomsAndRatesText.sub_2"
                  ></p>
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: {
        heading: "The conference centre",
        sub_1:
          "Scotland House Brussels offers a friendly and cosy venue in the heart of the EU district for meetings and events. We have modern facilities for seminars, training workshops, conferences, meetings, and receptions. We are also fully equipped to host hybrid meetings and events.",
        listStart: "Our facilities include:",
        roomsAndRatesHeading: "Conference centre rooms and rates",
      },
      roomsAndRatesText: {
        sub_1:
          "Prices don’t include Belgian VAT at 21%. A full day is considered as standard office working hours (9h-17h). Access to the conference centre is included in Scotland Europa membership fees. Scotland House residents and members enjoy discounted access to the conference centre.",
        sub_2:
          "For more information on the conference centre and rates, or to plan your event, please contact Helena at <a href='mailto:Helena.DeRaedemaeker@scotent.co.uk'>Helena.DeRaedemaeker@scotent.co.uk</a> or by phone on +32 2 282 83 15.",
      },
      bulletPoints: [
        { text: "State-of-the-art audio-visual equipment" },
        { text: "Natural light in all rooms" },
        { text: "Free Wi-Fi internet access" },
        { text: "Air conditioning" },
        {
          text: "Conference Centre Coordinator support leading to and on the day of your event",
        },
      ],
      roomsAndRatesBulletPoints: [
        { text: "Lomond - Capacity: 22 in u-shape style, 28 in boardroom style, 45 in theatre style - Full day 830€, half day 550€" },
        { text: "Ness - Capacity: 22 in U-shape style, 24 in boardroom style, 36 in theatre style - Full day 720€, half day 500€" },
        { text: "Nevis - Capacity: 12 in U-shape style, 16 in boardroom style, 22 in theatre style - Full day 660€, half day 440€" },
        { text: "Lomond & Ness - Capacity: 36 in U-Shape style, 45 in boardroom style, 85 in theatre style - Full day 1450€, half day 1000€" },
        {
          text: "Ness & Nevis - Capacity: 25 in U-shape style, 30 in boardroom style, 50 in theatre style - Full day 1120€, half day 830€",
        },
        { text: "Whole area (Lomond, Ness & Nevis) - Capacity: 48 in U-shape style, 54 in boardroom style, 130 in theatre style - Full day 1900€, half day 1350€" },
        { text: "Arran - Capacity: 10 in boardroom style - Full day 500€, half day 350€" },
        { text: "Reception area - full day 1600€, half day 1400€" },
      ],
    };
  },
};
</script>
<style>
.invlove-img {
  /*  margin-bottom: 50px;*/
}
.left-text {
  margin-bottom: 50px;
}
</style>
